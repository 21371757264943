// export * from 'o365.startup.ts';


import API from './data.api.ts';

export interface IUserSession {
    formats: any;
    amDesignator?: string,
    culture?: string,
    dayNames?: string[],
    dayNamesShort?: string[],
    decimalSeparator?: string,
    emailAddress?: string,
    firstName?: string,
    groupSeparator?: string,
    isDeveloper?: boolean,
    language?: string,
    lastName?: string,
    login?: string,
    mobileNumber?: string,
    monthNames?: string[],
    monthNamesShort?: string[],
    name?: string,
    personId?: number,
    pmDesignator?: string,
    shortDateFormat?: string,
    uiCulture?: string,
    userType?: string,
    [key: string]: unknown;
}

export interface IDataObjectConfig {
    id: string;
    appId?: string;
    viewName: string;
    distinctRows: boolean;
    uniqueTable: string;
    allowUpdate: boolean;
    allowInsert: boolean;
    allowDelete: boolean;
    appendData: boolean;
    selectFirstRowOnLoad: boolean;
    fields: Array<IDataObjectFieldConfig>;
    masterDataObject_ID: string;
    masterDetailDefinition: Array<IDataObjectMasterDetailDefinition>;
    clientSideHandler: string;
    maxRecords: number;
    dynamicLoading: boolean;
    whereClause: string;
    filterString: string;
    // [key: string]: unknown;
}

export interface IDataObjectFieldConfig {
    name: string;
    sortOrder: number;
    sortDirection: string;
    groupByOrder: number;
    groupByAggregate: string;
    [key: string]: unknown;
}

export interface IDataObjectMasterDetailDefinition {
    detailField: string;
    operator: string;
    masterField: string;
    [key: string]: unknown;
}

export interface IViewDefinitionField {
    fieldName: string;
    maxLength: number;
    dataType: string;
    computed: boolean;
    identity: boolean;
    hasDefault: boolean;
    nullable: boolean;
};

interface IAppInfo {
    id: string;
    isDebug: boolean;
    config?: string;
    dataObjects: IDataObjectConfig[];
    lastModified: string;
    viewDefinition: Record<string, IViewDefinitionField|undefined>;
};

interface IAppConfig {
    appDependencies?: Array<string>;
    CTAppID?: string;
    previewURL?: string;
    setupApp?: boolean;
    pwaSettings?: IAppConfigPwaSettings
    mobile?: IAppConfigMobileSettings,
    queryParameters?: Record<string, { default?: any }>
    [key: string]: any;
}

interface IAppConfigPwaSettings {
    title?: string;
    icon?: string;
    serviceWorkerId: string;
    entrypoint?: string;
    manifestId?: string;
}

interface IAppConfigMobileSettings {
    route: string;
    icon: string;
    async: boolean;
}

interface IApp {
    id: string;
    parentId?: string;
    parentTitle?: string;
    title?: string;
    fingerprint: string;
    db_objectfingerprint: string;
    proxyRequest?: string;
    filters: any;
    dataObjectConfigs: Map<string, IDataObjectConfig>;
    filterTemplates: any;
    viewDefinitions: Record<string, IViewDefinitionField | undefined>;
    isDebug: boolean;
    config: IAppConfig | null;
    /** Configured query parameters with default values from app config */
    queryParameters: Record<string, any>,
}

export interface ISiteSettings {
    dataGrid: {
        defaultSidePanelPosition: 'left' | 'right',
        allowSidePanelSwitching: boolean
    }
}


let userSession: IUserSession = {
    //we should replace this with default intl format
    formats:{
        "$": "$0 000",
        "$.00": "$0 000.00",
        "%": "0%",
        "*": "*",
        "/1000.0": "/1000.0",
        "/1000000.0": "/1000000.0",
        "0.0%": "0.0%",
        "1 234": "0 000",
        "1 234.1": "0 000.0",
        "1 234.12": "0 000.00",
        "1 234.1234": "0 000.0000",
        "123.12b": "0.00b",
        "123.12k": "0.00k",
        "123.1b": "0.0b",
        "123.1k": "0.0k",
        "dd.MM": "dd.MM",
        "dd.MM.yy": "dd.MM.yy",
        "dd.MM.yyyy": "dd.MM.yyyy",
        "dd.MM.yyyy HH:mm": "dd.MM.yyyy HH:mm",
        "dd.MMM": "dd.MMM",
        "dd.MMM.yyyy": "dd.MMM.yyyy",
        "dd.MMM.yyyy HH:mm": "dd.MMM.yyyy HH:mm",
        "Full Date Short Time": "dd MMMM yyyy HH:mm",
        "General Date Long Time": "dd/MM/yyyy HH:mm:ss",
        "General Date Short Time": "dd/MM/yyyy HH:mm",
        "HH:mm": "HH:mm",
        "Long Date": "dd MMMM yyyy",
        "Long Time": "HH:mm:ss",
        "MM.yyyy": "MM.yyyy",
        "Short Date": "dd/MM/yyyy",
        "Short Time": "HH:mm",
        "sql_date_time": "yyyy-MM-dd HH:mm:ss",
        "yyyymmdd": "yyyyMMdd",
        "yyyy-MM-dd": "yyyy-MM-dd"
    }
};

const app: IApp = {
    id: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-id"]'))?.content,
    parentId: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-parentid"]'))?.content,
    parentTitle: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-parenttitle"]'))?.content,
    title: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-title"]'))?.content,
    fingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-app-fingerprint"]'))?.content,
    db_objectfingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-dbobjectdefinition-fingerprint"]'))?.content,
    proxyRequest: (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content,
    filters: {},
    dataObjectConfigs: new Map<string, IDataObjectConfig>(),
    filterTemplates: {},
    viewDefinitions: {} as Record<string, IViewDefinitionField | undefined>,
    isDebug: false,
    config: null,
    queryParameters: {}
}

const userFingerPrint = (<HTMLMetaElement>document.querySelector('meta[name="o365-person-lastloggedin"]'))?.content;

const site = {
    fingerprint: (<HTMLMetaElement>document.querySelector('meta[name="o365-staticfiles-fingerprint"]'))?.content,
    oldGenUrl: (<HTMLMetaElement>document.querySelector('meta[name="o365-site-oldgenurl"]'))?.content
}

const localSiteSettings: Partial<ISiteSettings> = {};

/** 
 * Backend returns unexpected time formats for nb-NO locale (HH.mm.ss instead of HH:mm:ss)
 * Temp workaround to replace the wrong formats
 */
function correctNbFormats(_formats: any = {}) {
    if (_formats == null) { return; }
    const formats = { ..._formats };
    const formatsToReplace = ['Full Date Short Time', 'General Date Long Time', 'General Date Short Time', 'Long Time', 'Short Time'];
    formatsToReplace.forEach(format => {
        const currentFormat: string = formats[format]
        if (currentFormat == null) { return; }
        formats[format] = currentFormat.replace('HH.mm', 'HH:mm').replace('mm.ss', 'mm:ss').replace('dddd', 'iiii').replace('tt', 'aa');
    });
    return formats;
} 

let initializePromise: Promise<void> | null = null;
async function initialize() {
    if (initializePromise) { return initializePromise; }
    if (window['af']) { return; }
    let resolve = () => {};
    initializePromise = new Promise((res) => { resolve = res});

    const fingerPrint = app.db_objectfingerprint > app.fingerprint ? app.db_objectfingerprint : app.fingerprint;

    const appInfo: IAppInfo = await API.request({ requestInfo: `/nt/api/apps/${app.id}.${fingerPrint}.json`, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON });
    const localSiteSettingsPromise = loadLocalSiteSettings();

    app.viewDefinitions = appInfo.viewDefinition;

    app.isDebug = appInfo.isDebug ?? false;

    appInfo.dataObjects?.forEach((dataObject: any) => {
        app.dataObjectConfigs.set(dataObject.id, dataObject);
    });
    
    if (app.dataObjectConfigs.size > 0 && (!app.viewDefinitions || Object.keys(app.viewDefinitions).length === 0)) {
        // App has DataObjects but no view definitions were returned
        const warningMessage = `${app.id}.json has DataObject definitions but no view definitions. Most likely one of the DataObjects has invalid or unset view`;
        if (app.isDebug) {
            import('o365-vue-services').then((alertModule) => {
                alertModule.alert(warningMessage, 'warning', { autohide: true, delay: 10000,});
            });
        } else {
            console.warn(warningMessage);
        }
    }
    try {
        if (appInfo.config) {
            const appConfig: IAppConfig = JSON.parse(appInfo.config);
            
            app.config = appConfig;

            let promises = appConfig.appDependencies?.map(async (appDependency) => {
                await loadExternalAppConfig(appDependency);
            });
            document.querySelectorAll<HTMLMetaElement>(`meta[name^="o365-app-dependency-fingerprint-"`).forEach(dependency => {
                if (promises == null) { promises = []};
                const promise = loadExternalAppConfig(dependency.name.split('o365-app-dependency-fingerprint-')[1]);
                promises.push(promise);
            })
            
            if (promises) {
                await Promise.all(promises);
            }
        }
    } catch (error) {
        console.warn(error);
    }
     if (!userFingerPrint) { resolve(); return; }
    if (userFingerPrint === '0') { resolve(); return; }
    const originalFormats = userSession.formats;
    try {
        userSession = await API.request({ requestInfo: `/nt/api/usersession.${userFingerPrint}.json`, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON, headers: new Headers({ 'X-NT-API': 'true' }) });
        const formats = correctNbFormats(userSession.formats);
        if (Object.keys(formats).length > 0) {
            userSession.formats = formats;
        }
    } catch (error) {
        console.warn("Failed to parse user session: ", error);
    } finally {
        if (userSession.formats == null) {
            userSession.formats = originalFormats;
        }
    }
    await localSiteSettingsPromise;
    initializeConfiguredQueryParameters();
    resolve();
}

function getAppConfig(){
    return app;
}

const extarnalAppsConfigs = new Map<string, IAppInfo>();
const checkedApps = new Set<string>();

// Should be enough to just filter out o365-vue, but some apps could explicitly add other dependencies. 
const skipConfigs = new Set<string>([
    'o365-vue',
    'o365-modules',
    'o365-dataobject',
    'o365-filterobject',
    'o365-data-pagination',
    'o365-designer',
    'o365-vue-utils',
    'o365-data-properties',
    'o365-data-import',
    'o365-calendar-datagrid',
    'o365-vue-services',
    'o365-inputeditors',
    'o365-filter-components',
    'o365-data-export',
    'o365-ui-components',
    'o365-data-tree',
    'o365-mobile',
    'o365-datalookup',
    'o365-nodedata',
    'o365-data-summary',
    'o365-utils',
    'o365-fileupload',
    'o365-datagrid',
    'o365-offline-components',
    'o365-apryse'
]);

/**
 * Load configuration for a dependency app
 */
async function loadExternalAppConfig(pAppId: string) {
    if (!pAppId) { return; }

    if (!skipConfigs.has(pAppId) && !checkedApps.has(pAppId) && !extarnalAppsConfigs.has(pAppId)) {
        checkedApps.add(pAppId);
        let fingerPrint = document.querySelector<HTMLMetaElement>(`meta[name="o365-app-dependency-fingerprint-${pAppId}"]`)?.content ?? 0;
        if (!fingerPrint) {
            fingerPrint = app.db_objectfingerprint > app.fingerprint ? app.db_objectfingerprint : app.fingerprint;
        }

        const appInfo: IAppInfo = await API.request({ requestInfo: `/nt/api/apps/${pAppId}.${fingerPrint}.json`, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON });
        appInfo.viewDefinitions = appInfo.viewDefinition;
        appInfo.dataObjectConfigs = new Map<string, IDataObjectConfig>();

        appInfo.dataObjects?.forEach((dataObject: any) => {
            appInfo.dataObjectConfigs.set(dataObject.id, dataObject);
        });

        extarnalAppsConfigs.set(pAppId, appInfo);

        try {
            if (appInfo.config) {
                const appConfig: {
                    appDependencies?: string[]
                } = JSON.parse(appInfo.config);
                const promises = appConfig.appDependencies?.map(async (appDependency) => {
                    return loadExternalAppConfig(appDependency);
                });
                if (promises) {
                    await Promise.all(promises);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }
}

function getExternalAppConfig(pAppId: string) {
    return extarnalAppsConfigs.get(pAppId);
}

function getUserSession(){
    return userSession;
}

/** Initialize query parameters with their default values from applicaitons config */
function initializeConfiguredQueryParameters() {
    if (app?.config?.queryParameters == null) { return; }
    try {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => {
                if (prop == 'toString') {
                    return () => searchParams.toString();
                }
                return searchParams.get(prop as any)
            },
            set: (searchParams, prop, value) => {
                if (typeof prop != 'string') { return false; }
                searchParams.set(prop, value);
                return true;
            }
        });
        let defaultValuesFilled = false;
        Object.keys(app.config.queryParameters).forEach(key => {
            if (params[key] == null && app?.config?.queryParameters?.[key]?.default != null ) {
                defaultValuesFilled = true;
                params[key] = app?.config?.queryParameters?.[key]?.default;
            }

            Object.defineProperty(app.queryParameters, key, {
                get() {
                    const value = params[key] ?? app?.config?.queryParameters?.[key]?.default;

                    return app?.config?.queryParameters?.[key]?.type == 'number' && value != null 
                        ? parseInt(value)
                        : value;
                },
            })
        });
        if (defaultValuesFilled) {
            let url = new URL(window.location);
            url.search = params.toString();
            window.history.replaceState({}, '', url);
        }
    } catch (ex) {
        console.error(ex);
    }
}

function getLocalSiteSettings() {
    return localSiteSettings;
}

/**
 * Get site settings json with local overrides
 */
async function loadLocalSiteSettings() {
    const importMap = JSON.parse(document.querySelector('script[type="importmap"]')?.innerHTML ?? '');
    const schemaUrl = importMap.imports['o365.jsonSchemas.settings.json'];
    const localJsonUrl = importMap.imports['local.settings.json'];

    let localOverrides: ISiteSettings | null = null;

    const generateJsonFromSchema = (pSchema: any) => {
        let result: any = {};
        if (pSchema && pSchema.type === 'object' && pSchema.properties) {
            Object.keys(pSchema.properties).forEach(prop => {
                let propSchema = pSchema.properties[prop];
                if ('default' in propSchema) {
                    result[prop] = propSchema.default;
                } else if (propSchema.type === 'object') {
                    result[prop] = generateJsonFromSchema(propSchema);
                } else if (pSchema.required && pSchema.required.includes(prop)) {
                    result[prop] = null;
                }
            });
        }
        return result;
    }

    const merge = (targetObject: any, sourceObject: any) => {
        for (const key in sourceObject) {
            if (typeof sourceObject[key] === 'object' && !Array.isArray(sourceObject[key])) {
                if (targetObject[key]) {
                    targetObject[key] = merge(targetObject[key], sourceObject[key]);
                } else {
                    targetObject[key] = sourceObject[key];
                }
            } else {
                targetObject[key] = sourceObject[key];
            }
        }
        return targetObject;
    }


    const promises: Promise<void>[] = [];
    promises.push(
        API.request({ requestInfo: schemaUrl, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON})
            .then(schema => {
                const defaultSiteSettings = generateJsonFromSchema(schema);
                Object.assign(localSiteSettings, defaultSiteSettings);
            })
    );
    if (localJsonUrl) {
        promises.push(
            API.request({ requestInfo: localJsonUrl, method: 'GET', responseStatusHandler: false, responseBodyHandler: API.ResponseHandler.JSON })
                .then(json => {
                    localOverrides = json;
                })
        );
    }
    await Promise.all(promises);

    if (localOverrides && localSiteSettings) {
        merge(localSiteSettings, localOverrides);
    }
    return localSiteSettings;
}

export { getUserSession, getAppConfig, app, userSession, site, getExternalAppConfig, localSiteSettings, getLocalSiteSettings, initialize }
